:root {
  --rich-black: #042a2bff;
  --maximum-blue: #376996;
  --light-cyan: #cdedf6;
  --mandarin: #f7c548ff;
  --fire-engine-red: #c22229ff;
}
* {
  box-sizing: border-box;
  line-height: 2rem;
  /* line-height: 1.8rem; */
  font-weight: 400;
}
@font-face {
  font-family: "AvenirNext-Regular";
  src: url("./assets/AvenirNext-Regular.ttf");
}
@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("./assets/AvenirNext-DemiBold.ttf");
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
body {
  box-sizing: border-box;
  width: 100%;
  margin: 0px;
  font-family: "AvenirNextRegular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--rich-black);
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.red {
  background: var(--fire-engine-red);
}
.light-red {
  background: lightcoral;
}
.orange {
  background: var(--mandarin);
}
.cyan {
  background: var(--light-cyan);
}
.blue {
  background: var(--maximum-blue);
}
.black {
  background: var(--rich-black);
}
.green {
  background: lightgreen;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 5px 0;
}
h1 {
  font-size: 2rem;
  font-weight: 600;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 1rem;
}
p {
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: 0;
}
a {
  text-decoration: none;
  /* padding: 1rem 0.5rem; */
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.button {
  background-color: var(--fire-engine-red);
  border: 2px solid var(--fire-engine-red);
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.75rem;
  margin: 1rem 0;
}
.button-save {
  background-color: green;
  border: 2px solid green;
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.75rem;
  margin: 1rem 0;
}
.button:hover {
  background-color: var(--mandarin);
  border-color: var(--mandarin);
}
#main {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  align-items: center;
  padding: 0 4%;
  max-width: 100%;
  min-height: 100%;
  /* border-top: 1px solid var(--fire-engine-red); */
}
.logo-wrapper {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}
#logo {
  max-height: 55px;
}
.logo-wrapper h1 {
  font-size: 22px;
  color: var(--fire-engine-red);
  margin: 0;
  width: max-content;
}
#dropdown-wrapper {
  padding-right: 1rem;
}
#header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
#header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-border {
  display: block;
  padding-top: 1px;
  background: linear-gradient(to left,
      #042a2bff 0%,
      #5eb1bfff 15%,
      #cdedf6ff 50%,
      #ef7b45ff 75%,
      #c22229ff 100%);
}
#logo-wrapper-anchor {
  padding: 0;
}
.orthopatient-logo {
  max-height: 30%;
  max-width: 30%;
  margin: 0 auto;
  padding-bottom: 4rem;
}
@media screen and (min-width: 370px) {
  .logo-wrapper {
    display: flex;
    flex: 1 1 0;
    align-items: center;
  }
  .logo-wrapper h1 {
    font-size: 28px;
    color: var(--fire-engine-red);
    margin: 0;
    width: max-content;
  }
  #logo {
    width: auto;
    height: auto;
    max-height: 70px;
  }
}
.dropdown {
  display: flex;
  align-items: center;
  background: linear-gradient(var(--fire-engine-red), rgba(219, 43, 51, 0.789));
  border: 2px solid var(--fire-engine-red);
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.25rem;
}
.dropdown:active {
  box-shadow: -1px 1px 3px var(--mandarin);
  background-color: rgba(194, 34, 42, 0.846);
}
#hamburger-icon {
  width: 16px;
  height: 16px;
  filter: invert(100%);
}
#navigation {
  display: none;
  text-align: right;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
#navigation a {
  text-decoration: none;
  text-align: left;
  padding: 0rem 0.5rem 1rem 0.5rem;
  color: var(--fire-engine-red);
}
#navigation a:hover {
  animation: hover 0.3s ease forwards;
}
@keyframes hover {
  from {}
  to {
    color: var(--mandarin);
    text-decoration: underline;
  }
}
#adminMenu {
  padding: 0.5rem 1rem;
  background-color: var(--maximum-blue);
}
#adminMenu p {
  margin: 0;
  font-weight: 700;
  color: white;
}
#adminMenu a {
  text-decoration: none;
  padding: 1rem 0.5rem;
  color: white;
}
#adminMenu a:hover {
  color: var(--light-cyan);
}
#login-wrapper {
  padding: 5% 15%;
}
.actions a {
  text-decoration: none;
  color: var(--maximum-blue);
}
#hero {
  background-image: url("./assets/images/hero.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 35%;
  width: 100%;
  height: 350px;
  border-top: 1px solid var(--fire-engine-red);
  /* border-top: 1px solid var(--fire-engine-red); */
}
.headshot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#formal-bio {
  padding-top: 2rem;
  border-top: 3px solid var(--fire-engine-red);
  /* border-bottom: 3px solid var(--fire-engine-red); */
}
#formal-bio h1 {
  color: var(--maximum-blue);
}
#formal-headshot {
  display: block;
  max-width: 250px;
  height: auto;
  padding: 0 0 1rem 0;
}
.testimonial {
  width: 75%;
  padding: 1rem 1rem;
  margin: 1rem 0;
}
#testimonial-h3 {
  font-size: 1.5rem;
  color: var(--mandarin);
  margin: 0;
  border-bottom: 2px solid var(--mandarin);
}
#testimonial-h1 {
  font-size: 1.75rem;
  color: var(--mandarin);
  margin: 0;
  padding: 0.25rem 0;
  border-bottom: 2px solid var(--mandarin);
  text-align: left;
}
#slsurgical {
  height: 30px;
}
.mdc-logo {
  height: 115px !important;
  width: auto !important;
}
.fll-wrapper {
  padding-right: 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.footer-logo-links img {
  height: 75px;
}
#slsurgical {
  height: 30px;
  padding-right: 2.5%;
}
#upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20vh 0;
}
#uploads-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 4rem 0;
}
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.143);
  padding: 1rem;
  margin: 1.5rem;
}
.upload:hover {
  animation: fade-shadow 0.5s forwards;
}
.upload-link {
  font-size: 1.1rem;
  padding: 0;
  color: var(--fire-engine-red);
}
.upload-link:hover {
  text-decoration: underline;
  color: var(--mandarin);
}
.upload-date {
  font-size: 0.8rem;
}
#footer-links p {
  font-size: 0.8rem;
  padding: 0 0.5rem;
  width: 100%;
  margin: 0;
}
#testimonial-form form {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}
#post-wrapper {
  margin: 1rem 0;
  padding: 0 0.25rem;
}
.full {
  width: 100%;
  white-space: pre-wrap;
  display: block;
  overflow: none;
  margin: 0rem 0rem;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.143);
  border-radius: 5px;
  padding: 1rem;
}
@media screen and (min-width: 500px) {
  .full {
    width: 100%;
    white-space: pre-wrap;
    display: block;
    overflow: none;
    margin: 0rem 0rem;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.143);
    border-radius: 5px;
    padding: 3rem;
  }
}
@media screen and (min-width: 600px) {
  #adminMenu {
    display: flex;
    justify-content: space-between;
  }
  #formal-headshot {
    display: inline;
    max-width: 300px;
    height: auto;
    float: left;
    padding: 0 1rem 0rem 0;
  }
  .headshot-wrapper {
    display: block;
  }
  .footer-logo-links img {
    height: 85px;
  }
  .footer-logo-links img {
    height: 85px;
  }
  #footer-links p {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  #post-wrapper {
    margin: 1rem 0;
    padding: 0 1rem;
  }
}
#formal-headshot img {
  height: 100%;
}
#informal-bio {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#informal-bio img {
  max-width: 300px;
  height: auto;
  padding-bottom: 1rem;
}
.logo-links {
  display: flex;
  justify-content: flex-end;
  /* padding: 2rem 0 1rem 0; */
}
.logo-links a {
  padding: 0;
}
.logo-links img {
  /* max-height: 100%; */
  height: 57px;
  padding: 0 1rem;
}
.logo-links.ytlogo {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.logo-links.ytlogo img {
  max-height: 30px;
}
.left-column {
  display: flex;
  flex-direction: column;
  padding-top: 4%;
}
.right-column {
  padding-top: 4%;
}
.right-column img {
  max-width: 100%;
  padding-bottom: 4%;
}
.post {
  white-space: pre-wrap;
  display: block;
  overflow: none;
  margin: 2rem 1rem;
  padding: 1rem;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.143);
  border-radius: 5px;
}
@keyframes fade-shadow {
  0% {
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.143);
  }
  100% {
    box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.311);
  }
}
.post:hover {
  animation-name: fade-shadow;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.post h1 {
  color: var(--rich-black);
  padding-top: 1rem;
}
.post-title {
  margin: 5px 0 0 0;
}
.post-anchor:hover {
  animation: changecolor 0.25s ease forwards;
}
@keyframes changecolor {
  from {
    color: var(--rich-black);
  }
  to {
    color: var(--fire-engine-red);
  }
}
.post-date {
  font-size: smaller;
}
.post-category {
  font-size: smaller;
  color: var(--fire-engine-red);
  border-top: 1px solid rgb(169, 169, 169);
  /* border-bottom: 1px solid rgb(169, 169, 169); */
}
.post-wrapper {
  display: flex;
}
.post-body {
  max-width: 100%;
  /* height: 400px; */
  overflow: hidden;
}
.post-thumbnail {
  display: flex;
  justify-content: start;
  padding: 0 1rem 1rem 0;
  width: 25%;
  height: auto;
}
.post-image {
  display: inline;
  float: right;
  padding: 1rem 0 1rem 1rem;
  width: 400px;
  height: auto;
}
#testimonial-form {
  color: white;
  background-color: var(--maximum-blue);
  /* background-color: var(--light-cyan); */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}
#testimonial-form h1 {
  padding: 1rem 1rem;
}
@media screen and (max-width: 767px) {
  .post-wrapper {
    flex-direction: column;
  }
  .post-thumbnail {
    width: 50%;
    padding: 0.5rem 0;
  }
}
@media screen and (min-width: 768px) {
  #testimonial-form form {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
  .footer-logo-links p {
    text-align: center;
    width: 200px;
    font-size: 0.8rem;
  }
  .footer-logo-links a {
    text-align: center;
    font-size: 0.8rem;
  }
}
#testimonial-form input {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem 0.25rem;
}
#testimonial-form button {
  color: white;
  padding: 0.25rem 0;
  width: 25%;
  background-color: var(--mandarin);
  border: 2px solid var(--mandarin);
  border-radius: 10px;
}
#testimonial-form button:hover {
  background-color: rgba(239, 123, 69, 0.847);
  text-decoration: underline;
}
#testimonials-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 1rem;
}
#testimonial-slideshow {
  display: flex;
  flex-direction: row;
  height: 275px;
  max-height: 300px;
  flex: 1 1 1;
  padding: 1rem 0;
  min-height: 100%;
  /* border-top: 2px solid var(--mandarin); */
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 1rem;
}
#testimonial-slideshow h3 {
  font-weight: 400;
  font-size: large;
}
.slide {
  display: none;
}
.slide-active {
  display: block;
  width: 90%;
  animation-name: fade;
  animation-duration: 1s;
  overflow: auto;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.left-arrow {
  color: var(--maximum-blue);
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding-right: 0.5rem;
}
.right-arrow {
  color: var(--maximum-blue);
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding-left: 0.5rem;
}
.form-container input {
  padding: 0.5rem;
}
.form-textarea {
  min-height: 400px;
  padding: 0.55rem;
}
.form-url {
  max-height: 50px;
  padding: 0.5rem;
}
.form-container {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.post-form input,
.post-form select,
.post-form textarea {
  width: 100%;
  margin-bottom: 1rem;
}
.post-form {
  display: flex;
  flex-direction: column;
  /* width: 75%; */
  align-items: center;
  /* height: 500px; */
  width: 100%;
  padding: 0 5%;
}
.paragraph {
  padding-bottom: 1rem 0;
  /* border-top: 3px solid var(--maximum-blue); */
}
.post-form h2 {
  color: var(--maximum-blue);
  font-size: 1rem;
}
#previews-wrapper h1 {
  /* background-color: var(--maximum-blue); */
  background-color: white;
  color: var(--maximum-blue);
  border-bottom: 2px solid var(--maximum-blue);
  text-shadow: -1px 1px 1px var(--light-cyan);
  text-align: left;
  font-size: 1.75rem;
}
.preview {
  display: flex;
  flex-direction: column-reverse;
}
.preview h1 {
  font-size: 1.25rem;
  text-align: center;
  /* background-color: var(--fire-engine-red); */
  color: white;
  padding: 0.25rem 0;
}
.preview h2 {
  font-size: 1.5rem;
}
#news-preview {
  background-color: var(--fire-engine-red);
  margin-bottom: 0;
}
#patient-preview {
  background-color: var(--mandarin);
}
#pt-preview {
  background-color: var(--maximum-blue);
}
.post-anchor,
.post-anchor:any-link {
  color: inherit;
  text-decoration: none;
  padding: 0;
}
.ellipse {
  display: block;
  color: var(--maximum-blue);
  font-weight: bold;
  font-size: 0.75rem;
  text-shadow: -1px 1px var(--light-cyan);
  margin: 0;
}
#slideshow-wrapper {
  overflow: none;
  height: 500px;
  margin: 0 auto;
}
/* Slideshow container */
#slideshow-container {
  max-width: 500px;
  height: 100%;
  margin: 1rem auto 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-top: 2px solid var(--maximum-blue); */
  /* border-bottom: 2px solid var(--maximum-blue); */
}
/* Hide the images by default */
.mySlides {
  display: none;
}
.mySlides img {
  height: 100%;
  max-height: 500px;
  max-width: 100%;
  object-fit: fill;
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active,
.dot:hover {
  background-color: #717171;
}
/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1s;
}
.text-center {
  text-align: center;
}
#footer {
  background-color: var(--maximum-blue);
}
#footer-links-container {
  background-color: var(--maximum-blue);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-content: center;
}
#footer-copyright {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
#footer-copyright h1 {
  width: 100%;
  text-align: right;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  color: var(--mandarin);
}
.footer-logo-links {
  color: white;
  padding: 1rem 0;
  background-color: var(--maximum-blue);
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-logo-links a {
  color: var(--mandarin);
  text-decoration: underline;
  display: flex;
  justify-content: center;
  /* padding: 0.5rem; */
  text-align: center;
  font-size: 0.8rem;
}
.footer-logo-links p {
  padding: 0.5rem 1.5rem 0 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0;
}
#footer-links {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}
#footer-links h4 {
  padding: 0.5rem 0.5rem;
}
#footer-links a {
  font-size: 0.8rem;
  color: var(--mandarin);
  text-decoration: underline;
  padding: 0.5rem 0.5rem;
}
#footer-links a:hover {
  color: var(--white);
}
#orthoinfo {
  padding: 1rem 1rem;
  height: 60px;
  padding: none;
}
@keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .orthopatient-logo {
    max-width: 60%;
    padding: 2rem 0;
  }
  .logo-links.ytlogo img {
    max-height: 20px;
  }
}
@media screen and (min-width: 600px) {
  .fll-wrapper {
    padding-right: 2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
  }
  #footer-links-container {
    background-color: var(--maximum-blue);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  .logo-links img {
    height: 57px;
    padding: 0 1rem;
  }
  #footer-links {
    text-align: left;
  }
}
@media screen and (min-width: 1300px) {
  #main,
  #header {
    padding: 0 2.5%;
  }
  #header {
    flex-direction: row;
    align-items: center;
  }
  .dropdown {
    display: none;
  }
  #navigation {
    display: flex;
    align-items: center;
    padding: 0;
    flex-direction: row;
  }
  #navigation a {
    padding: 0 0.6rem;
    width: max-content;
    font-weight: 500;
  }
  #main {
    flex-direction: row;
    flex: 1 1 0;
    align-items: flex-start;
    /* border-top: 1px solid var(--mandarin) */
  }
  #header-wrapper {
    padding: 0;
  }
  .left-column {
    max-height: 100%;
    width: 80%;
    padding-right: 3%;
    /* border-right: 1px solid var(--mandarin); */
  }
  .right-column {
    height: 100%;
    width: 20%;
    padding-left: 3%;
  }
  .orthopatient-logo {
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 0;
  }
  .form-container {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .post-form input,
  .post-form select,
  .post-form textarea {
    width: 100%;
    margin-bottom: 1rem;
  }
  #posts-wrapper {
    padding: 2% 15%;
    display: flex;
    flex-direction: column-reverse;
  }
  #informal-bio img {
    max-width: 100%;
  }
  #footer-links {
    padding: 1.5rem 0 1.5rem 2.5%;
  }
  #slideshow-wrapper {
    overflow: none;
    height: 350px;
  }
  #slideshow-container {
    border-top: 2px solid var(--maximum-blue);
    border-bottom: 2px solid var(--maximum-blue);
  }
  #testimonial-slideshow {
    margin: 0 2rem;
    margin-bottom: 1rem;
  }
  #testimonial-form form {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
  #post-wrapper {
    padding: 2% 15%;
  }
  .logo-links.ytlogo {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
  }
}
@media screen and (min-width: 1400px) {
  #header {
    padding: 0.5rem 10%;
  }
  #main {
    padding: 0 12%;
  }
  #footer {
    padding: 0 12%;
  }
  #footer-links,
  .fll-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .logo-links.ytlogo {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
  }
}